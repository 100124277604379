<template>
 <v-row class="pa-0">


      <v-col cols="12" class="mt-0 pt-0">

         <v-flex  xs12 sm12 md4 lg5 xl5 class="float-right">
          <v-text-field  justify-center  v-model="search"  append-icon="search" label="Busqueda"
          single-line hide-details></v-text-field> 
          </v-flex>

      </v-col>



      <v-col v-for="(item, index) in resultQuery" :key="index" xs="12" sm="12" md="6" lg="6" xl="6">

         <v-card class="pa-0">
          <v-card-title>
            <span>{{ item.nombre }}</span>
            <v-spacer></v-spacer>

            <v-btn small color="success" @click="listar()">
            <v-icon dark>sync</v-icon>
            </v-btn>
          </v-card-title>

            <v-card-text class="pa-0">
              <v-divider></v-divider>
               <v-row>
               <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="ml-3">


                <!--vista con 3 sensores-->
               

                  <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]!=null && item.chartData_s3[1][0]!=null" max-width="430" max-height="320" class="ml-1 pl-1 d-none d-sm-none d-md-none d-lg-flex d-xl-flex">

                    <GChart class="d-inline-flex ml-1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                      <GChart class="d-inline-flex mr-1 ml-11"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s2"
                    :options="chartOptions"
                    ></GChart>

                      <GChart class="d-inline-flex"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s3"
                    :options="chartOptions"
                    ></GChart>


                   
              
                  </v-img>

                  <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]!=null && item.chartData_s3[1][0]!=null"  max-width="510" max-height="320" class="d-none d-sm-flex d-md-flex d-lg-none d-xl-none">

                     <GChart class="d-inline-flex ml-6" v-if="item.chartData_s1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                     <GChart class="d-inline-flex mr-16 ml-11" v-if="item.chartData_s2"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s2"
                    :options="chartOptions"
                    ></GChart>

                    <GChart class="d-inline-flex"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s3"
                    :options="chartOptions"
                    ></GChart>


                   
              
                  </v-img>

                    <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]!=null && item.chartData_s3[1][0]!=null"  max-width="285" max-height="320" class="d-inline-flex d-sm-none d-md-none d-lg-none d-xl-none">

                     <GChart class="d-inline-flex" v-if="item.chartData_s1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                     <GChart class="d-inline-flex" v-if="item.chartData_s2"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s2"
                    :options="chartOptions"
                    ></GChart>

                     <GChart class="d-inline-flex"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s3"
                    :options="chartOptions"
                    ></GChart>

                   


                   
              
                  </v-img>

             <!--vista con 2 sensores-->



                 <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]!=null && item.chartData_s3[1][0]==null" max-width="430" max-height="320" class="ml-1 pl-1 d-none d-sm-none d-md-none d-lg-flex d-xl-flex">

                    <GChart class="d-inline-flex ml-16"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                      <GChart class="d-inline-flex ml-16"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s2"
                    :options="chartOptions"
                    ></GChart>

                  


                   
              
                  </v-img>

                  <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]!=null && item.chartData_s3[1][0]==null "  max-width="510" max-height="320" class="d-none d-sm-flex d-md-flex d-lg-none d-xl-none">

                     <GChart class="d-inline-flex ml-16" v-if="item.chartData_s1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                     <GChart class="d-inline-flex  ml-16" v-if="item.chartData_s2"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s2"
                    :options="chartOptions"
                    ></GChart>

           


                   
              
                  </v-img>

                    <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]!=null && item.chartData_s3[1][0]==null"  max-width="285" max-height="320" class="d-inline-flex d-sm-none d-md-none d-lg-none d-xl-none">

                     <GChart class="d-inline-flex ml-5" v-if="item.chartData_s1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                     <GChart class="d-inline-flex ml-10" v-if="item.chartData_s2"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s2"
                    :options="chartOptions"
                    ></GChart>


                   


                   
              
                  </v-img>


  <!--vista con 1 sensores-->


                 <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]==null && item.chartData_s3[1][0]==null" max-width="430" max-height="320" class="ml-1 pl-1 d-none d-sm-none d-md-none d-lg-flex d-xl-flex">

                    <GChart class="d-inline-flex ml-16"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                  </v-img>

                  <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]==null && item.chartData_s3[1][0]==null"  max-width="510" max-height="320" class="d-none d-sm-flex d-md-flex d-lg-none d-xl-none">

                     <GChart class="d-inline-flex ml-16" v-if="item.chartData_s1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

                   
                  </v-img>

                  <v-img src="@/assets/trailer.jpg" v-if="item.chartData_s1[1][0]!=null && item.chartData_s2[1][0]==null && item.chartData_s3[1][0]==null"  max-width="285" max-height="320" class="d-inline-flex d-sm-none d-md-none d-lg-none d-xl-none">

                     <GChart class="d-inline-flex ml-5" v-if="item.chartData_s1"
                    :settings="{ packages: ['corechart', 'gauge'] }"
                    type="Gauge"
                    :data="item.chartData_s1"
                    :options="chartOptions"
                    ></GChart>

              
                   </v-img>



                  <span class="text-caption"><v-icon color="success" class="mx-2">update</v-icon>{{ getDate(item.temp_fecha)}}</span>


               </v-col>

               </v-row>


            </v-card-text>

         </v-card>

      </v-col>
 </v-row>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

import { GChart } from 'vue3-googl-chart';



export default {
  name: 'Home',
  components: {
    GChart
  },

   data: () => ({

     Arrayflota:[],


      chartData: [ 
        ['Temp'],
        [30]],
      chartOptions: {
        chart: {
          title: 'Temperatura',
          subtitle: 'Valores en grados',
        },
         width: 165,
           height: 95,
          redFrom: 28,
           redTo: 100,
          yellowFrom:20,
           yellowTo: 27,
          minorTicks: 5
      },

      search:'',

   
   }),

    created () {

       this.$root.$refs.Home = this;  //metodo estara en home desacivar alarmas
       
      this.listar();
      this.ejecutarTimer();
     
    },
    methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),

          listar(){

                 this.Arrayflota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);
                       let result=response.data;


                          result.map(function(x){
                            console.log(x);

                        
                             x.chartData_s1=[['Temp'], [x.s1]];
                             x.chartData_s2=[['Temp'], [x.s2]];
                             x.chartData_s3=[['Temp'], [x.s3]];
                           

                             x.chartOptions={
                                chart: {
                                      title: 'Temperatura',
                                      subtitle: 'Valores en grados',
                                    },
                                    width: 255,
                                      height: 110,
                                      redFrom: 28,
                                      redTo: 100,
                                      yellowFrom:20,
                                      yellowTo: 27,
                                      minorTicks: 5
                             };
                           

                             me.Arrayflota.push(x);

                          
                          })
                       



                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });

          },


          ejecutarTimer(){

              this.timer = setInterval(() => {
                  this.listar();
                }, 60000);

      },

        detenerTimer(){
         clearInterval(this.timer);
      },

       getDate(evaluar) {
        let date = new Date(evaluar),
        year = date.getFullYear(),
        month = (date.getMonth() + 1).toString(),
        formatedMonth = (month.length === 1) ? ("0" + month) : month,
        day = date.getDate().toString(),
        formatedDay = (day.length === 1) ? ("0" + day) : day,
        hour = date.getHours().toString(),
        formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
        minute = date.getMinutes().toString(),
        formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
        second = date.getSeconds().toString(),
        formatedSecond = (second.length === 1) ? ("0" + second) : second;
        return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },

    },
     computed:{

       ...mapState(['usuario', 'token']),

        resultQuery(){
                    if(this.search){
                    return this.Arrayflota.filter((item)=>{
                      return this.search.toLowerCase().split(' ').every(v => item.nombre.toLowerCase().includes(v))
                    })
                    }else{
                      return this.Arrayflota;
                    }
              }


    }

}
</script>
